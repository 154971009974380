// internacjonalizacja
var lang =  {
	'required' : 'Pole wymagane',
	'choose_option_first' : 'Wybierz najpierw opcję',
	'choose_variant_first' : 'Wybierz najpierw wariant',
	'type_here_phrase' : 'Wpisz szukaną frazę',
	'show' : 'ROZWIŃ',
	'hide' : 'ZWIŃ'
};
// funkcja wyrównująca wysokość 2 kolumn - wymaga załadowania okna (window.load)
align = function(left, right) {
	if (left && right) {
		if (left.outerHeight() > right.outerHeight()) {
			right.height(left.outerHeight()-parseInt(right.css('padding-top'))-parseInt(right.css('padding-bottom')));
		} else {
			left.height(right.outerHeight()-parseInt(left.css('padding-top'))-parseInt(left.css('padding-bottom')));
		}
	}
}
// funkcja sprawdzająca czy element istnieje w tablicy
inArray = function(needle, haystack) {
    var length = haystack.length;
    for(var i = 0; i < length; i++) {
        if(haystack[i] == needle) return true;
    }
    return false;
}
// funkcja rozciągająca selektor do spodu przeglądarki
$.fn.toBottom = function() {
	if ($(this).length > 0) {
		var offset = $(this).offset();
		var height = $(this).outerHeight(true);
		var height_raw = $(this).height();
		var win_height = $(window).height();
		if (offset.top+height < win_height) {
			var extra_height = win_height-(offset.top+height);
			$(this).height(height_raw+extra_height);
		}
	}
}
// czy obiekt istnieje
$.fn.exist = function(){ return Boolean($(this).length > 0); };
// funkcja wyrównująca do linii dolnej
$.fn.toBaseline=function(a){var b=0;$(this).each(function(){if(b<$(this).height()){b=$(this).height();}});var c=a==true?'margin-top':'padding-top';$(this).each(function(){$(this).css(c,(b-$(this).height()));});}
// funkcja centrująca $('a').setValign('parent',true/false,'top/bottom');
$.fn.setValign=function(a,b,c){var d=b==true?'margin-':'padding-';var e=d+c;$(this).each(function(){if((c=='top')||(c=='bottom')){var f=$(this).height();var g=$(this).closest(a).height();$(this).css(e,((g-f)/2));}else{var f=$(this).width();var g=$(this).closest(a).width();$(this).css(e,((g-f)/2));}});}
// ustaw eveny
function setEvens() {
	$('table').each(function(){
		$('tr', this).removeClass('even');
		$('tbody > tr:even', this).addClass('even');
	});
}
// funkcja wyrównująca wysokość wszystkich kolumn - wymaga załadowania okna (window.load)
function setHeight(obj) {
	var h = 0;
	$(obj).each(function(){
		if ($(this).outerHeight() > h) {
			h = $(this).outerHeight()
		}
	});
	if (h) {
		$(obj).outerHeight(h);
	}
}
// przyklejane menu
function stickIt() {
  var orgElementPos = $('.original').offset();
  orgElementTop = orgElementPos.top;               

  if ($(window).scrollTop() >= (orgElementTop)) {
    // scrolled past the original position; now only show the cloned, sticky element.

    // Cloned element should always have same left position and width as original element.     
    orgElement = $('.original');
    coordsOrgElement = orgElement.offset();
    leftOrgElement = coordsOrgElement.left;  
    widthOrgElement = orgElement.css('width');
    $('.cloned').css('left',leftOrgElement+'px').css('top',0).css('width',widthOrgElement).show();
    $('.original').css('visibility','hidden');
  } else {
    // not scrolled past the menu; only show the original menu.
    $('.cloned').hide();
    $('.original').css('visibility','visible');
  }
}
// scrollowanie
function scrollToAnchor(anchor){
    var tag = (anchor == '#') ? $('body') : $(anchor);
    $('html,body').animate({scrollTop: tag.offset().top}, 'slow');
}

// załadowany dokument
$(document).ready(function(){

	// przewijanie
	$('.scroll').click(function(){
        var anchor = $(this).attr('href');
        scrollToAnchor(anchor);
        return false;
	});
	
	// przyklejanie menu
	if ($('#top_menu').length > 0) {
		var offset = $('#top_menu').offset().top;
		$(window).scroll(function() {
		    if ($(this).scrollTop() > offset && $('#logo_container').hasClass('sticy') == false) {  
		        $('#logo_container').addClass('sticky');
		    } else {
		        $('#logo_container').removeClass('sticky');
		    }
		});
	}
	
	// slider
	$('#slider').css('visibility','hidden');
	if ($('.bxslider').exist && $('.bxslider li').length > 1) {
		$('.bxslider').bxSlider({
			mode: 'fade',
			auto: true,
			captions: false,
			controls: false,
			pager: true,
			slideMargin: 0,
			pause: 5000,
			onSliderLoad: function(){
				$('#slider').css('visibility','visible');
			}
		});
	} else {
		$('#slider').css('visibility','visible');
	}

	// karulzela obrazków
	if ($('.jcarousel').length > 0) {
		var jcarousel = $('.jcarousel');
        jcarousel
	        .on('jcarousel:reload jcarousel:create', function () {
	            var width = jcarousel.innerWidth();
	            var width_one = jcarousel.find('li:first-child div').outerWidth();
	            width = width_one;
	            jcarousel.jcarousel('items').css('width', width + 'px');
	        })
	        .jcarousel({
	            wrap: 'circular',
	            scroll: 1,
	            auto: 2
	        });
	
	        $('.jcarousel-control-prev')
	            .jcarouselControl({
	                target: '-=1'
	            });
	
	        $('.jcarousel-control-next')
	            .jcarouselControl({
	                target: '+=1'
	            });
	
	        $('.jcarousel-pagination')
	            .on('jcarouselpagination:active', 'a', function() {
	                $(this).addClass('active');
	            })
	            .on('jcarouselpagination:inactive', 'a', function() {
	                $(this).removeClass('active');
	            })
	            .on('click', function(e) {
	                e.preventDefault();
	            })
	            .jcarouselPagination({
	                perPage: 1,
	                item: function(page) {
	                    return '<a href="#' + page + '">' + page + '</a>';
	                }
	            });
	}

	// menu mobilne
	$('.top_menu_lnk').click(function(){
            if ($('.top_menu_wrapper').hasClass('open')) {
		$('.top_menu_wrapper').removeClass('open');
                $(this).removeClass('open');
            } else {
                $('.top_menu_wrapper').addClass('open');
                $(this).addClass('open');
            }
            return false;
	});


	// drop and down submenu
	$('.dropdown li a').click(function(){
		$('.dropdown a').not($(this)).not($(this).parent().parent().prev()).removeClass('active');
		$('.dropdown a').not($(this)).next().not($(this).parent().parent()).removeClass('active');
		$('.dropdown a').not($(this)).next().not($(this).parent().parent()).addClass('hide');
		if ( ! $(this).next().hasClass('active')) {
			$(this).addClass('active');
			$(this).next().removeClass('hide');
			$(this).next().addClass('active');
		} else {
			$(this).removeClass('active');
			$(this).next().removeClass('active');
			$(this).next().addClass('hide');
		}
		if ($(this).next().hasClass('sub_menu')) {
			return false;
		}
	});
	$('body').click(function(e){
		if ($(e.target).closest('.dropdown').length === 0) {
//			$('.dropdown a').removeClass('active');
//			$('.dropdown a').next().removeClass('active');
			$('.dropdown a').next().addClass('hide');
		}
	});
	
	// oznaka ajaxa 
	$.ajaxSetup({
		beforeSend: function(){
			//console.log( "Starting request..." );
			$('body *:not("script")').css('cursor','wait');
		},
		complete: function(){
			//console.log( "Request complete." );
			$('body *:not("script")').css('cursor','');
		}
	});
	
	// tabelka zebra
	setEvens();
	//$('table tr:even').addClass('even');

	// dodaj klasę usuwającą obramowanie z pól checkbox i radio
	$('input[type=radio],input[type=checkbox]').addClass('no_border');

	// nowe okno
	$('#ssi a, a.blank').click(function(){
		window.open($(this).attr('href'));
		return false;
	});

	// dodaj czerwoną gwiazdkę dla pól wymaganych
	if ($('.required')) {
		$('.label.required').append(' <span style="color:red; cursor:help;" title="'+lang['required']+'">*</span> ');
		$('.required').next().find('.input, .select, .textarea').attr('required', 'required');
		$('input.required, select.required, textarea.required').attr('required', 'required');
	}

	// // maskowane inputy
	// if ($('.tel')) { $('.tel').mask('(+99) 999-999-999'); }
	// if ($('.fax')) { $('.fax').mask('(+99) 999-999-999'); }
	// if ($('.postcode')) { $('.postcode').mask('99-999'); }
	// if ($('.pesel')) { $('.pesel').mask('99999999999'); }
	// if ($('.nip')) { $('.nip').mask('999-999-99-99'); }
	// if ($('.regon')) { $('.regon').mask('999999999'); }
	// if ($('.date')) { $('.date').mask('99/99/9999'); }
	// if ($('.hour')) { $('.hour').mask('99:99'); }
	
	// zmiana hasła
	if($('#change_pass').prop('checked')){
		$('.pass').show();
	} else {
		$('.pass').hide();
	}
	$('#change_pass').click(function(){
		if ($(this).prop('checked')) {
			$('.pass').show();
		} else {
			$('.pass').hide();
		}
	});

	// pokaż / ukryj
	$('.show_hide').click(function(){
		var anchor = $(this).text();
		anchor = anchor.replace(' ↓', '');
		anchor = anchor.replace(' ↑', '');
		var next = $(this).parent().next();
		if (next.css('display') == 'block') {
			next.slideUp('fast');
			$(this).text(anchor+' ↓');
		} else {
			next.slideDown('fast');
			$(this).text(anchor+' ↑');
		}
		return false;
	});
	$('.show_hide').after('<div class="clear"></div>').parent().next().hide();



	// blokuj klik pustych linków
	/*
	$('a[href="#"]').not('.bjqs a').each(function(){
		if ( ! $(this).attr('onclick')) {
			$(this).click(function(){
				return false;
			});
		}
	});
	*/

	// obsługa cookies
	if ($('#cookies').length > 0) {
		$('body').css('padding-bottom', $('#cookies').outerHeight());
		$('#cookies_ok').click(function(){
			$.post('ajax.php', { cookies_ok: true }, function(){
				$('#cookies').remove();
				$('body').css('padding-bottom', '0');
			});
			return false;
		});
	}

	// scroll to top
    $(window).scroll(function(){
        if ($(this).scrollTop() > 150) {
            $('.totop').fadeIn();
        } else {
            $('.totop').fadeOut();
        }
    });
    $('.totop').click(function(){
        $('html, body').animate({
            scrollTop: 0
        }, 400);
        return false;
    });
	
});
//okno załadowane
